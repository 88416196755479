import { gql } from "apollo-boost";

const QUERY_PROSPECT_PLAN = gql`
  query companyCreditSummary($companyId: ID!) {
    companyCreditSummary(companyId: $companyId)
  }
`;

const QUERY_CURRENT_PLAN = gql`
  query currentPlan($companyId: ID!) {
    currentPlan(companyId: $companyId) {
      plan
      monthlyFee
      nextPaymentDate
    }
  }
`;

const QUERY_PULSEM_PLAN = gql`
  query pulseMPlan($companyId: ID!) {
    pulseMSubscription(companyId: $companyId) {
      pulseMAccountLevel {
        name
      }
      lastFour
      expirationDate
      monthlyFee
      technicianCount
      nextPaymentDate
      status
      pulseMAccountLevelId
      nameOnCard
    }
  }
`;

const QUERY_CAMPAIGN_CREDITS = gql`
  query campaignCredits($companyId: ID!) {
    campaignCredits(companyId: $companyId) {
      credits
      freePostcardCredits
      nextPaymentDate
    }
  }
`;



const QUERY_TEXT_CREDITS = gql`
  query textCredits($companyId: ID!) {
    textCredits(companyId: $companyId) {
      credits
      twilioNumber
    }
  }
`;

const QUERY_SUBSCRIPTION_DETAILS = gql`
  query subscriptionDetails($companyId: ID!) {
    currentPlan(companyId: $companyId) {
      plan
      nextPaymentDate
      monthlyFee
      accountClass
      paid
    }
    subscriptionDetails(companyId: $companyId) {
      companyId
      addOns {
        name
        amount
        cost
        shortName
      }
      cardType
      expirationDate
      lastFour
      nameOnCard
      onHoldSince
      status
      billing {
        address1
        address2
        city
        country
        email
        firstName
        lastName
        state
        zip
        countryLabel
        stateLabel
      }
    }
    pulseMSubscription(companyId: $companyId) {
      pulseMAccountLevel {
        name
      }
      billing {
        address1
        address2
        city
        country
        email
        firstName
        lastName
        state
        zip
        countryLabel
        stateLabel
      }
      lastFour
      expirationDate
      monthlyFee
      technicianCount
      nextPaymentDate
      status
      pulseMAccountLevelId
      nameOnCard
    }
  }
`;

const QUERY_PAST_STATEMENTS = gql`
  query monthlyReceipts(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
  ) {
    monthlyReceipts(
      companyId: $companyId
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      receipts {
        status
        date
        url
      }
      total
      page
      limit
    }
  }
`;

const QUERY_BILLING_PREFERENCES = gql`
  query billingPreferences($companyId: ID!) {
    companySettings(companyId: $companyId) {
      companyId
      sendInvoiceEmailsTo
    }
    currentPlan(companyId: $companyId) {
      plan
      nextPaymentDate
      monthlyFee
      accountClass
      paid
    }
    pulseMSubscription(companyId: $companyId) {
      pulseMAccountLevel {
        name
      }
      billing {
        address1
        address2
        city
        country
        email
        firstName
        lastName
        state
        zip
        countryLabel
        stateLabel
      }
      lastFour
      expirationDate
      monthlyFee
      technicianCount
      nextPaymentDate
      status
      pulseMAccountLevelId
      nameOnCard
    }
    subscriptionDetails(companyId: $companyId) {
      companyId
      cardType
      expirationDate
      lastFour
      nameOnCard
      onHoldSince
      status
      billing {
        address1
        address2
        city
        country
        email
        firstName
        lastName
        state
        zip
        countryLabel
        stateLabel
      }
    }
  }
`;

const QUERY_TRANSACTIONS = gql`
  query companyTransactions(
    $companyId: ID!
    $status: String!
    $page: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
  ) {
    companyTransactions(
      companyId: $companyId
      page: $page
      limit: $limit
      sortBy: $sortBy
      status: $status
      sortOrder: $sortOrder
    ) {
      transactions {
        amount
        eventDescription
        status
        subtotal
        taxCityAmt
        taxCountyAmt
        taxStateAmt
        transactionDate
      }
      limit
      page
      total
    }
  }
`;

const QUERY_LIST_ALL_STATES = gql`
  query listStates {
    statesUS: listStates(country: "US") {
      name
      code
    }
    statesCA: listStates(country: "CA") {
      name
      code
    }
  }
`;

const QUERY_LIST_COUNTRIES = gql`
  query listCountries {
    listCountries {
      countries
    }
  }
`;

export {
  QUERY_TRANSACTIONS,
  QUERY_CURRENT_PLAN,
  QUERY_PULSEM_PLAN,
  QUERY_CAMPAIGN_CREDITS,
  QUERY_TEXT_CREDITS,
  QUERY_SUBSCRIPTION_DETAILS,
  QUERY_PAST_STATEMENTS,
  QUERY_BILLING_PREFERENCES,
  QUERY_LIST_ALL_STATES,
  QUERY_LIST_COUNTRIES,
  QUERY_PROSPECT_PLAN,
};
