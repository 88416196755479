import React, { Component } from "react";
import "./CompanySettings.scss";
import informationLogo from "../../../img/information.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  GET_COMPANY_SETTINGS,
  GET_CATEGORIES,
} from "./graphql_queries/queries";
import { gql } from "apollo-boost";
import { COOKIES, APPOLO_FETCH_POLICY } from "../../../shared/Config";
import { loadCookie } from "../../../shared/SessionHelper";
import BlockUi from "react-block-ui";
import { UPDATE_COMPANY_SETTINGS } from "./graphql_queries/mutations";
import ProfileSettingTitleBar from "./ProfileSettingTitleBar";
import Popup from "reactjs-popup";
import SettingsContext from "src/shared/context/SettingsContext";
import { addToast } from "src/utils/ToastUtil";

const UPLOADED_FILE_SIZE = 4;
const UPLOADED_FILE_WIDTH = 400;
const UPLOADED_FILE_HEIGHT = 200;

class CompanyInformation extends Component {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      blocking: false,
      companyId: loadCookie(COOKIES.COMPANY_ID),
      token: loadCookie(COOKIES.AUTH_TOKEN),
      categories: [],
      file: null,
      filename: "",
      fileType: false,
      fileSize: false,
      filePixel: false,
      logoRemoved: false,
    };
  }

  /**
   * Life cycle hook.
   */
  UNSAFE_componentWillMount() {
    this.getCategories();
    this.getCompanyInformation();
  }

  /**
   * Handler for remove the logo.
   */
  onRemoveLogo() {
    this.setState({
      logoRemoved: true,
      file: null,
      filename: "",
      fileType: false,
      fileSize: false,
      filePixel: false,
    });
  }

  /**
   *  Gets categories list
   */
  getCategories() {
    this.toggleBlocking();
    this.props.client
      .query({
        query: gql`
          ${GET_CATEGORIES}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        if (response && response.data && response.data.categories) {
          this.setState({ categories: response.data.categories });
        }
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * Gets company information
   */
  getCompanyInformation() {
    this.toggleBlocking();
    this.props.client
      .query({
        query: gql`
          ${GET_COMPANY_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: { id: parseInt(this.state.companyId, 10) },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        this.toggleBlocking();
        if (response && response.data && response.data.company)
          this.setState({
            companyData: response.data.company,
            file: response.data.company.logoUrl,
          });
      })
      .catch((error) => {
        this.toggleBlocking();
        console.log("Error in getting company information: ", error);
      });
  }

  /**
   * Method invoked to toggle the blocking
   */
  toggleBlocking() {
    this.setState({ blocking: !this.state.blocking });
  }

  /**
   * Method invoked to get uploaded logo
   * @param {*} e Event
   */
  getUploadLogo(e) {
    let files = e.target.files;
    let allowedExtension = ["image/png", "image/jpeg"];
    let img = new Image();
    if (files && files.length >= 1) {
      const fileType = files[0].type;
      if (allowedExtension.includes(fileType)) {
        const fileSize = files[0].size / 1024 / 1024; // in MB
        if (fileSize <= UPLOADED_FILE_SIZE) {
          img.src = URL.createObjectURL(files[0]);
          img.onload = () => {
            let width, height;
            width = img.width;
            height = img.height;
            if (
              width >= UPLOADED_FILE_WIDTH &&
              height >= UPLOADED_FILE_HEIGHT
            ) {
              let reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = () => {
                this.setState({
                  logoRemoved: false,
                  fileInBase64: this.getBase64Logo(reader.result),
                  filename: files[0].name,
                  file: img.src,
                  filePixel: false,
                  fileSize: false,
                  fileType: false,
                });
              };
              reader.onerror = function (error) {
                console.log("Error: ", error);
              };
            } else {
              this.updateFileValidationStatus(false, false, true);
            }
          };
        } else {
          this.updateFileValidationStatus(false, true, false);
        }
      } else {
        this.updateFileValidationStatus(true, false, false);
      }
    }
  }

  /**
   * Update the file validation status
   * @param fileType - Boolean field
   * @param fileSize - Boolean field
   * @param filePixel - Boolean field
   */
  updateFileValidationStatus(fileType, fileSize, filePixel) {
    this.setState({
      fileType: fileType,
      fileSize: fileSize,
      filePixel: filePixel,
    });
  }

  /**
   * Provides the base64 logo
   * @param strBase64
   * @returns {*}
   */
  getBase64Logo(strBase64) {
    if (strBase64) {
      strBase64 = strBase64.substring(
        strBase64.indexOf(",") + 1,
        strBase64.length,
      );
    }
    return strBase64;
  }

  /**
   * Method invoked to update the company information
   * @param {*} data data
   */
  uploadCompanySetting(data) {
    this.toggleBlocking();
    this.props.client
      .mutate({
        mutation: gql`
          ${UPDATE_COMPANY_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: this.processParams(data),
      })
      .then((response) => {
        this.setState({
          fileSize: false,
          filePixel: false,
          fileType: false,
          filename: "",
        });
        addToast({
          type: "success",
          message: "Company Information updated successfully.",
        });
        this.toggleBlocking();
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Failed to update company Information.",
        });
        this.toggleBlocking();
      });
  }

  /**
   * method to create the params for updating company information
   * @param {*} data
   */
  processParams(data) {
    let params = {
      companyId: parseInt(this.state.companyId, 10),
    };
    if (data.description) {
      params["companyDescription"] = data.description;
    }
    if (data.website) {
      params["website"] = data.website;
      this.context.setWebsiteEnabled(true);
    }
    if (this.state.fileInBase64) {
      params["logoBase64"] = this.state.fileInBase64;
    }
    if (this.state.filename && this.state.fileInBase64) {
      params["logoOriginalFilename"] = this.state.filename;
    }
    if (data.primaryIndustry) {
      params["primaryCategory"] = parseInt(data.primaryIndustry, 10);
    }
    if (data.additionalIndustry1) {
      params["secondaryCategory"] = parseInt(data.additionalIndustry1, 10);
    }
    if (data.additionalIndustry2) {
      params["tertiaryCategory"] = parseInt(data.additionalIndustry2, 10);
    }
    params["isLogoRemoved"] = this.state.logoRemoved;
    if (this.state.logoRemoved) {
      this.context.setLogoEnabled(false);
    } else {
      this.context.setLogoEnabled(true);
    }
    return params;
  }

  /**
   * Set visibility texting toast pop up.
   * @param visible -> boolean value, true if popup needs to be visible.
   */
  enabledToast = (visible) => {
    this.setState({ showToast: visible });
  };

  /**
   * Provide the class as per the instruction.
   * @param validateFor - Validation for the instruction.
   * @returns {*} - The class for image instruction
   */
  getClassForImageValidations(validateFor) {
    const { filename } = this.state;
    if (validateFor) {
      return "error-message";
    } else if (!validateFor && filename) {
      return "success-message";
    } else {
      return "upload-logo-instruction-valid";
    }
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullname: this.state.companyData
            ? this.state.companyData.companyName
            : "",
          primaryIndustry: this.state.companyData?.primaryCategory?.id || "",
          description: this.state.companyData
            ? this.state.companyData.companyDescription
            : "",
          website: this.state.companyData ? this.state.companyData.website : "",
          additionalIndustry1:
            this.state.companyData && this.state.companyData.secondaryCategory
              ? this.state.companyData.secondaryCategory.id
              : "",
          additionalIndustry2:
            this.state.companyData && this.state.companyData.tertiaryCategory
              ? this.state.companyData.tertiaryCategory.id
              : "",
          activeCampaignsCount: this.state.companyData
            ? this.state.companyData.activeCampaignsCount
            : 0,
        }}
        validationSchema={Yup.object().shape({
          primaryIndustry: Yup.string().required("Field required"),
          description: Yup.string().required("Field required"),
          website: Yup.string().required("Field required"),
          additionalIndustry1: Yup.string(),
          additionalIndustry2: Yup.string(),
        })}
        onSubmit={(fields) => {
          this.uploadCompanySetting(fields);
        }}
        render={({ errors, status, touched, values }) => (
          <Form>
            <div id="company-information">
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div className="row">
                  <div className="col-lg-10 col-md-12">
                    <div className="row">
                      <div className="col-12 ml-sm-2 mb-1">
                        <ProfileSettingTitleBar
                          title={"Company Information"}
                          subTitle={
                            "This section contains your company information like logo, company name and industry, etc"
                          }
                        />
                      </div>
                    </div>
                    <div className="row offset-sm-1">
                      <div className="col-12 col-md-11">
                        <div className="uploadLogoBlock">
                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="d-inline-flex w-100">
                                {this.state.file ? (
                                  <img
                                    src={this.state.file}
                                    alt="Upload Logo"
                                    className="uploadLogoImage"
                                  />
                                ) : (
                                  <div className="uploadLogoImage d-flex align-items-center">
                                    <label>Company Logo</label>
                                  </div>
                                )}
                                <div className="button-block">
                                  <div className="row">
                                    <div className="col-12">
                                      <button
                                        type="button"
                                        className="btn btn-primary upload-logo-button buttons"
                                      >
                                        <input
                                          type="file"
                                          className="inputFile"
                                          onChange={($event) =>
                                            this.getUploadLogo($event)
                                          }
                                          accept="image/png,image/jpeg"
                                        ></input>
                                        Upload Logo
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary buttons"
                                        onClick={() => this.onRemoveLogo()}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                  <div className="upload-logo-instruction d-none d-md-block">
                                    <span className="upload-logo-instruction-valid">
                                      - The image will appear on your reviews
                                      page and custom email invitations.
                                    </span>
                                    <br />
                                    <span
                                      className={this.getClassForImageValidations(
                                        this.state.fileType,
                                      )}
                                    >
                                      - Only JPEG and PNG image formats are
                                      accepted.
                                    </span>
                                    <br />
                                    <span
                                      className={this.getClassForImageValidations(
                                        this.state.filePixel,
                                      )}
                                    >
                                      - The image should be 400 x 200 pixels or
                                      larger.
                                    </span>
                                    <br />
                                    <span
                                      className={this.getClassForImageValidations(
                                        this.state.fileSize,
                                      )}
                                    >
                                      - The file size can not exceed 4MB.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-md-none d-sm-block p-3">
                            <div className="col-12">
                              <div className="upload-logo-instruction">
                                <span className="upload-logo-instruction-valid">
                                  - The image will appear on your reviews page
                                  and custom email invitations.
                                </span>
                                <br />
                                <span
                                  className={this.getClassForImageValidations(
                                    this.state.fileType,
                                  )}
                                >
                                  - Only JPEG and PNG image formats are
                                  accepted.
                                </span>
                                <br />
                                <span
                                  className={this.getClassForImageValidations(
                                    this.state.filePixel,
                                  )}
                                >
                                  - The image should be 400 x 200 pixels.
                                </span>
                                <br />
                                <span
                                  className={this.getClassForImageValidations(
                                    this.state.fileSize,
                                  )}
                                >
                                  - The file size can not exceed 4MB.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label className="input-label required-field">
                              Name
                            </label>
                            <br />
                            <Field
                              component="input"
                              disabled
                              name="fullname"
                              className={"form-control"}
                              placeholder="Plumbing Demo"
                              type="text"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label className="input-label required-field">
                              Primary Industry
                            </label>

                            <Popup
                              trigger={
                                <img
                                  src={informationLogo}
                                  alt="Information Logo"
                                  className="informationLogo ml-1"
                                ></img>
                              }
                              position="right center"
                              on="hover"
                            >
                              <div className="p-2">
                                If you want to update the categories of your
                                company, you must un approve all campaigns.
                              </div>
                            </Popup>
                            <br />
                            <Field
                              component="select"
                              name="primaryIndustry"
                              disabled={values.activeCampaignsCount > 0}
                              className={
                                "form-control" +
                                (errors.primaryIndustry &&
                                touched.primaryIndustry
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option disabled>Select Primary Industry</option>
                              {this.state.categories
                                .sort((categoryA, categoryB) =>
                                  categoryA.name.localeCompare(categoryB.name),
                                )
                                .map((category, index) => (
                                  <option key={index} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="primaryIndustry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label className="input-label mt-1">
                              Additional Industry 1
                            </label>
                            <br />
                            <Field
                              as="select"
                              disabled={values.activeCampaignsCount > 0}
                              className="form-control component-border"
                              data-selenium-id="customer-filter-list"
                              name="additionalIndustry1"
                            >
                              <option value="">
                                Select Additional Industry
                              </option>
                              {this.state.categories.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label className="input-label mt-1">
                              Additional Industry 2
                            </label>
                            <br />
                            <Field
                              as="select"
                              disabled={values.activeCampaignsCount > 0}
                              className="form-control component-border"
                              data-selenium-id="customer-filter-list"
                              name="additionalIndustry2"
                            >
                              <option value="">
                                Select Additional Industry
                              </option>
                              {this.state.categories.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <label className="description-label required-field">
                              Description
                            </label>
                            <br />
                            <Field
                              component="textarea"
                              name="description"
                              className={
                                "form-control" +
                                (errors.description && touched.description
                                  ? " is-invalid"
                                  : "")
                              }
                              rows="3"
                              maxLength={165}
                            />
                            <label className="pull-right description-limit">
                              {values.description
                                ? values.description.length
                                : 0}
                              /165
                            </label>
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-6">
                            <label className="website-label required-field">
                              Website
                            </label>
                            <br />
                            <Field
                              component="input"
                              type="text"
                              name="website"
                              className={
                                "form-control" +
                                (errors.website && touched.website
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="website"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row mt-4 ml-0 mb-5">
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </Form>
        )}
      />
    );
  }
}

export default CompanyInformation;
